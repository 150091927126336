@font-face {
  font-family: 'Fugue';
  src: url('../public/fonts/fugue/fugue-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Visby';
  src: url('../public/fonts/visby_sans_serif/VisbyMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('../public/fonts/frutiger-webfont/Frutiger_UBS.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:"Montserrat";
  src: url('../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  
}